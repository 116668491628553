<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="审核状态">
				<el-select v-model="filters.examineStatus" placeholder="请选择" style="width: 120px;" clearable>
					<el-option label="待审核" value="1"></el-option>
					<el-option label="审核通过" value="2"></el-option>
					<el-option label="审核驳回" value="3"></el-option>
				</el-select>
			</el-form-item>
            <el-form-item>
                <el-button type="primary" @click="getData">查询</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" border style="width: 100%;">
            <el-table-column prop="userName" header-align="center" align="center" label="用户姓名"></el-table-column>
            <el-table-column prop="doctorName" header-align="center" align="center" label="医生姓名"></el-table-column>
            <el-table-column prop="serviceType" header-align="center" align="center" label="服务类型">
				<template slot-scope="scope">
					{{scope.row.serviceType === 1 ? '图文问诊' : ''}}
				</template>
			</el-table-column>
            <el-table-column prop="star" header-align="center" align="center" label="星等"></el-table-column>
            <el-table-column prop="content" header-align="center" align="center" label="评价内容"></el-table-column>
            <el-table-column prop="examineStatus" header-align="center" align="center" label="审核状态">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.examineStatus === 1">待审核</el-tag>
					<el-tag type="success" v-if="scope.row.examineStatus === 2">审核通过</el-tag>
					<el-tag type="danger" v-if="scope.row.examineStatus === 3">审核驳回</el-tag>
				</template>
			</el-table-column>
            <el-table-column prop="createdAt" header-align="center" align="center" label="创建时间"></el-table-column>
            <el-table-column prop="updatedAt" header-align="center" align="center" label="审核时间">
				<template slot-scope="scope">
					{{scope.row.examineStatus === 1 ? '' : scope.row.updatedAt}}
				</template>
			</el-table-column>
            <el-table-column prop="examineUser" header-align="center" align="center" label="审核人员"></el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-button v-if="[1, 3].indexOf(scope.row.examineStatus) > -1" type="text" size="small" @click="apply(scope.row.id, 2)">通过</el-button>
					<el-button v-if="[1, 2].indexOf(scope.row.examineStatus) > -1" type="text" size="small" @click="apply(scope.row.id, 3)">驳回</el-button>
				</template>
			</el-table-column>
        </el-table>
		<div style="float: right; margin-top: 20px;" v-if="dataList.length">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			form: {},
			formLabelWidth: '120px',
			filters: {
				examineStatus: ''
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.getData();
	},
	methods: {
		search () {
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('appraiseList', {
				page: this.currentPage,
				limit: this.pageSize,
				examineStatus: this.filters.examineStatus
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows;
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		apply (id, status) {
			this.$root.request('appraiseApply', {
				id,
				examineStatus: status
			}).then((data) => {
				if (data && data.ok === 1) {
					this.$root.elMsg('操作成功');
					this.getData();
				}
			});
		}
	}
};
</script>